<template>
  <div class="section-zupus">
    <h5>Find family records of {{ fullNameEn }} {{ fullNameCn }}</h5>
    <img
      class="image lazyload"
      width="800"
      height="220"
      :data-src="$getAsset('/assets/services/zupus/zupu-preview-optimized.png')"
    />
    <div class="bold">Family Tree Records (Zupus)</div>
    <p>
      Zupus are private documents compiled by families to record the names and details of ancestors. They can include
      several hundreds of years of family history, including: dates, names, accomplishments, but also surname origins,
      and migration histories.
    </p>
    <router-link :to="searchRouterTo">{{ linkText }}</router-link>
  </div>
</template>

<script>
import {TAB_ID_FULL_TEXT, TAB_ID_SOURCES} from '@common/utils/consts.search';

export default {
  props: {
    fullNameEn: String,
    fullNameCn: String,
    zupuCount: Number,
    textMatchesCount: Number,
    searchSurname: String,
    searchFirstname: String,
  },
  computed: {
    isAncestralZupuSearch() {
      return this.textMatchesCount > 0;
    },
    searchRouterTo() {
      if (this.isAncestralZupuSearch) {
        return {
          name: 'search-all-records',
          query: {q: this.searchFirstname, last_name: this.searchSurname, tab: TAB_ID_FULL_TEXT},
        };
      }
      return {name: 'search-tool-zupu', query: {clan_name: this.searchSurname, tab: TAB_ID_SOURCES}};
    },
    linkText() {
      if (this.isAncestralZupuSearch) {
        return `View ${this.textMatchesCount.toLocaleString('en')} potential records of ${this.searchSurname} ${
          this.searchFirstname || ''
        }`;
      }
      return `View ${this.zupuCount.toLocaleString('en')} potential ${this.searchSurname} family records`;
    },
  },
  name: 'SectionZupus',
};
</script>

<style scoped lang="scss">
.bold {
  margin-top: 16px;
}
a {
  font-weight: bold;
}
</style>
