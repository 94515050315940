<template>
  <div class="section-more-records-item">
    <router-link :to="toRoute" class="details-link text-lg">{{ displayName }}</router-link>
    <div class="text-sm source-title">
      <book-icon :size="20"></book-icon>
      <span>{{ collectionData || sourceData }}</span>
    </div>
    <div class="text-md">
      <div class="meta-item" v-if="birthData">
        <div class="label">Birth:</div>
        <div class="value">{{ birthData }}</div>
      </div>
      <div class="meta-item" v-if="deathData">
        <div class="label">Death:</div>
        <div class="value">{{ deathData }}</div>
      </div>
      <div class="meta-item" v-if="residenceData">
        <div class="label">Residence:</div>
        <div class="value">{{ residenceData }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDateLocationHighlighted, getFormattedResidenceText, truncateText} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';
import BookIcon from 'vue-material-design-icons/BookOpenOutline';

export default {
  props: {
    item: Object,
  },
  computed: {
    toRoute() {
      return getPersonRecordDetailRoute(this.item.person.code, this.fullNameEn);
    },
    fullNameEn() {
      return getFullNameEn(this.item.person.first_names, this.item.person.surnames);
    },
    fullNameCn() {
      return getFullNameCn(this.item.person.first_names, this.item.person.surnames);
    },
    fullName() {
      return [this.fullNameEn, this.fullNameCn].join(' ').trim();
    },
    displayName() {
      return this.fullName || this.businessName || 'Unknown';
    },
    sourceData() {
      const {title_en, title_ch, publication_year, time_period_start, time_period_end} = this.item.source;
      const title = truncateText(title_en || title_ch || 'Unknown', 98);
      const year =
        publication_year ||
        (time_period_start || time_period_end ? `${time_period_start || '...'}-${time_period_end || '...'}` : '');
      return year ? `${title}, ${year}` : title;
    },
    collectionData() {
      return this.item.source.collection_names ? this.item.source.collection_names.join(', ') : '';
    },
    birthData() {
      return getDateLocationHighlighted(this.item.person.birth_date, this.item.person.birth_location, false);
    },
    deathData() {
      return getDateLocationHighlighted(this.item.person.death_date, this.item.person.death_location, false);
    },
    residenceData() {
      return this.item.person.residence_list.map(residence => getFormattedResidenceText(residence, false)).join(', ');
    },
  },
  components: {BookIcon},
  name: 'SectionMoreRecordsItem',
};
</script>

<style scoped lang="scss">
.section-more-records-item {
  border-bottom: 1px solid $neutral-200;
  padding: 8px 16px;
  .details-link {
    font-weight: 500;
  }
  .source-title {
    margin: 4px 0 8px;
    color: $neutral-500;
    .material-design-icon {
      vertical-align: text-bottom;
      margin-right: 8px;
    }
  }
  .meta-item {
    display: flex;
    align-items: center;
    gap: 8px;
    .label {
      color: $neutral-500;
    }
  }
}
</style>
